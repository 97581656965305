import wasteManagementClient from '../../services/wasteManagementClient';
import { wasteManagementUrls } from '../../appConfig';

class ContainerOrderService {
  static getContainerOrders(expires, toDate, ignoreCache = false) {
    return wasteManagementClient.get(wasteManagementUrls.containerOrders, {
      params: {
        expires,
        toDate,
      },
      cache: { ignoreCache },
    }).then(response => {
      if (response.data && response.status === 200) {
        return response.data;
      } else {
        return [];
      }
    }).catch(error => {
      console.error(error);
      throw error;
    });
  }
}

export default ContainerOrderService;
