import customerConstants from './customerConstants';
import loadingActions from '../loading/loadingActions';
import CustomersService from '../../../services/CustomersService';

function getCustomers(ignoreCache = false, silent = false) {
  return dispatch => {
    if (!silent) {
      dispatch(loadingActions.addLoadingInProgress(customerConstants.GET_CUSTOMERS));
    }

    return CustomersService.getCustomers(ignoreCache)
      .then(
        (customers) => {
          const sortedCustomers = [...customers]
            .sort((a, b) => a.name.localeCompare(b.name));
          dispatch(success(sortedCustomers));
        },
        (error) => dispatch(failure(JSON.stringify(error))),
      ).finally(() => {
        dispatch(loadingActions.removeLoadingInProgress(customerConstants.GET_CUSTOMERS));
      });
  }

  function success(customers) {
    return { type: customerConstants.GET_CUSTOMERS_SUCCESS, customers };
  }

  function failure(error) {
    return { type: customerConstants.GET_CUSTOMERS_FAILURE, error }
  }
}

export default {
  getCustomers,
}
