import { datePattern } from '../components/shared/dateUtility';

const routes = {
  administration: {
    base: '/administration',
    pattern: `^/administration(.*)$`,
    vehicles: {
      base: '/administration/vehicles',
      pattern: `^/administration/vehicles(.*)$`,
    },
    vehicleSettings: {
      path: '/administration/vehicles/:vehicleId',
    },
  },
  orders: {
    base: '/orders',
    pattern: `^/orders(.*)$`,
    path: `/orders/:date(${datePattern})`,
  },
  callordersPattern: `^/callorders(.*)$`,
  callordersPath: '/callorders',
  callorderDetailsPath: '/callorders/:orderId',
  serviceListsPattern: `^/servicelists(.*)$`,
  serviceListsPath: '/servicelists',
  serviceOrders: {
    path: '/servicelists/:listId',
    pattern: '^/servicelists(.*)$',
  },
  serviceOrderDetailsPath: '/servicelists/:listId/:listItemId',
  routeOptimalizationWizardPath: '/servicelists/add',
  orderDetails: {
    path: `/orders/:date(${datePattern})/:orderId`,
    pattern: `^/orders(.*)$`,
  },
  routeStopDetails: {
    base: 'routeStopDetails',
    path: `/orders/:date(${datePattern})/:orderId/routeStopDetails`,
  },
  reportsPath: '/reports',
  reportsPattern: '^/reports(.*)$',
  routeAdminPagePath: '/routesAdmin',
  routeAdminPagePattern: '^/routesAdmin(.*)$',
  impersonationManagerPath: '/impersonation',
  impersonationManagerPattern: '^/impersonation(.*)$',
  userAdminPath: '/userAdmin',
  userAdminPattern: '^/userAdmin(.*)$',
};

export default routes;
