import customerConstants from './customerConstants';

const initialState = {
  customers: [],
}

export default function customersReducer(state = initialState, action) {
  switch (action.type) {
    case customerConstants.GET_CUSTOMERS_SUCCESS:
      return {
        customers: action.customers,
      }
    case customerConstants.GET_CUSTOMERS_FAILURE:
      return {
        ...state,
        error: action.error,
      }
    default:
      return state;
  }
}
