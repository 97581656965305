import React, { Component, ReactNode, RefObject } from 'react';
import { History, Location } from 'history';
import moment from 'moment';
import { Splitter, SplitterPaneProps } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import { HOCComponentRef } from '../../utils';
import Navbar from '../navbar';
import Orders from '../orders';
import OrderDetails from '../orderDetails';
import MapWrapper from '../map';
import { MapWrapper as MapWrapperComponent } from '../map/MapWrapper';
import MapErrorBoundary from '../map/MapErrorBoundary';
import { Route, Switch, Redirect } from 'react-router-dom';
import RouteStopDetails from '../routeStopDetails';
import CacheRefresher from '../cacheRefresher';
import Reports from '../reports';
import RouteAdminPage from '../routeAdminPage';
import routes from '../../common/routes';
import ServiceLists from '../serviceLists';
import ServiceOrders from '../serviceOrders';
import ServiceOrderDetails from '../serviceOrderDetails';
import ContainerOrders from '../containerOrders';
import ContainerOrderDetails from '../containerOrderDetails';
import ImpersonationManager from '../impersonationManager';
import classNames from 'classnames';
import UserAdmin from '../userAdmin';
import VehicleSettings from '../vehicleSettings';
import RouteOptimalizationWizard from '../routeOptimalizationWizard';
import { Toastr } from '../../utils/Toastr';
import { locale } from '../../common/localization';
import { WithAuth0Props, withAuth0 } from '@auth0/auth0-react';
import { IUser } from '../../models';

import './main.scss';
import NoAccess from '../noAccess/noAccess';
import AdministrationPage from '../adminisitrationPage/AdministrationPage';
import ProfileMenu from '../profileMenu';

export interface IMainProps {
  location: Location;
  history: History;
  isAdmin: boolean;
  isLocalAdmin: boolean;
  authentication: IUser;
}
export interface IMainState {
  panes: Array<SplitterPaneProps>;
  isLeftPanelCollapsed: boolean;
  isProfileMenuOpen: boolean;
}
class Main extends Component<IMainProps, IMainState, WithAuth0Props> {
  public readonly state: IMainState = {
    panes: [{ size: 'max(calc(50% - 7.5px), 700px)' }, {}],
    isLeftPanelCollapsed: false,
    isProfileMenuOpen: false,
  };

  public readonly onLoad = (): void => {
    this.mapRef.current?.getWrappedInstance()?.invalidateSize();
  };

  public readonly mapRef: RefObject<HOCComponentRef<MapWrapperComponent>> =
    React.createRef();

  public componentDidMount(): void {
    window.addEventListener('load', this.onLoad);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('load', this.onLoad);
  }

  public onLayoutChange(panes: Array<SplitterPaneProps>): void {
    this.mapRef.current?.getWrappedInstance()?.invalidateSize();
    this.setState({ panes });
  }

  public getLeftSplitterSize(): number {
    const sizeString = this.state.panes[0].size;
    return Number(sizeString.substring(0, sizeString.length - 2));
  }

  private toggleLeftPanel = (): void => {
    this.setState((prevState) => ({
      isLeftPanelCollapsed: !prevState.isLeftPanelCollapsed,
    }));
    if (this.state.isLeftPanelCollapsed) {
      this.adjustSplitter('50%');
    } else {
      this.adjustSplitter('10%');
    }
  };

  private adjustSplitter = (newSize: string) => {
    this.setState(
      {
        panes: [{ size: newSize }, {}],
      },
      () => {
        window.dispatchEvent(new Event('resize'));
      }
    );
  };

  public render(): ReactNode {
    const { isAdmin, isLocalAdmin, authentication } = this.props;
    const currentDate = moment().format('YYYY-MM-DD');
    const leftPaneSize = this.getLeftSplitterSize();
    const leftPaneClasses = classNames('container-fluid h-100 pt-2 main-pane', {
      'pane-sm': leftPaneSize < 800,
    });
    const isHaveAccess = true;
    return (
      <>
        <Navbar history={this.props.history} />
        <ProfileMenu />
        <div className="wrapper">
          {isHaveAccess ? (
            <Splitter
              panes={this.state.panes}
              onLayoutChange={(state) => this.onLayoutChange(state)}
              className={`main-splitter ${
                this.state.isLeftPanelCollapsed ? 'collapsed' : 'expanded'
              }`}
            >
              <div className={leftPaneClasses}>
                <Switch>
                  <Redirect
                    exact
                    from={routes.orders.base}
                    to={`${routes.orders.base}/${currentDate}`}
                  />
                  <Route
                    exact
                    path={routes.orders.path}
                    render={(props) => (
                      <Orders {...props} orderTypes={[0, 6]} />
                    )}
                  />
                  <Route
                    exact
                    path={routes.callordersPath}
                    component={ContainerOrders}
                  />
                  <Route
                    exact
                    path={routes.serviceListsPath}
                    component={ServiceLists}
                  />
                  <Route
                    exact
                    path={routes.routeOptimalizationWizardPath}
                    component={RouteOptimalizationWizard}
                  />
                  <Route
                    exact
                    path={routes.orderDetails.path}
                    component={OrderDetails}
                  />
                  <Route
                    exact
                    path={routes.routeStopDetails.path}
                    component={RouteStopDetails}
                  />
                  <Route
                    exact
                    path={routes.routeAdminPagePath}
                    render={() => {
                      if (!isAdmin && !isLocalAdmin) {
                        Toastr.error(locale.general._accessError);
                        return <Redirect to="/" />;
                      }
                      return <RouteAdminPage />;
                    }}
                  />
                  <Route exact path={routes.reportsPath} component={Reports} />
                  <Route
                    exact
                    path={routes.serviceOrders.path}
                    component={ServiceOrders}
                  />
                  <Route
                    exact
                    path={routes.serviceOrderDetailsPath}
                    component={ServiceOrderDetails}
                  />
                  <Route
                    exact
                    path={routes.callorderDetailsPath}
                    component={ContainerOrderDetails}
                  />
                  <Route
                    exact
                    path={routes.impersonationManagerPath}
                    render={() => {
                      if (!isAdmin) {
                        Toastr.error(locale.general._accessError);
                        return <Redirect to="/" />;
                      }
                      return <ImpersonationManager />;
                    }}
                  />
                  <Route
                    exact
                    path={routes.userAdminPath}
                    render={() => {
                      if (!isAdmin) {
                        Toastr.error(locale.general._accessError);
                        return <Redirect to="/" />;
                      }
                      return <UserAdmin />;
                    }}
                  />
                  <Route
                    exact
                    path={routes.administration.base}
                    render={(props) => (
                      <AdministrationPage
                        {...props}
                        authentication={authentication}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={routes.administration.vehicleSettings.path}
                    render={(props) => {
                      if (!isAdmin && !isLocalAdmin) {
                        Toastr.error(locale.general._accessError);
                        return <Redirect to="/" />;
                      }
                      return <VehicleSettings {...props} />;
                    }}
                  />
                  <Route>
                    <Redirect to={`${routes.orders.base}/${currentDate}`} />
                  </Route>
                </Switch>
              </div>
              <div className="right-panel-container">
                <Button
                  onClick={this.toggleLeftPanel}
                  className="custom-splitter-button"
                >
                  {this.state.isLeftPanelCollapsed ? '>' : '<'}
                </Button>
                <MapErrorBoundary>
                  <MapWrapper ref={this.mapRef as any} />
                </MapErrorBoundary>
              </div>
            </Splitter>
          ) : (
            <NoAccess />
          )}
        </div>
        <CacheRefresher />
      </>
    );
  }
}
export default withAuth0(Main);
