import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import ErrorMessage from './ErrorMessage';
import { DatePicker as KendoDatePicker } from '@progress/kendo-react-dateinputs';

export default function DatePicker({
  name,
  label,
  labelCol,
  format,
  popupClass,
  disabled = false,
}) {
  const { getFieldProps, touched, errors } = useFormikContext();

  return (
    <Form.Group as={Row}>
      <Form.Label column sm={labelCol}>
        {label}
      </Form.Label>
      <Col sm={12 - labelCol}>
        <KendoDatePicker
          {...getFieldProps(name)}
          format={format}
          className="date-picker"
          popupSettings={{ popupClass }}
          valid={!touched[name] || !errors[name]}
          disabled={disabled}
        />
        <ErrorMessage name={name} />
      </Col>
    </Form.Group>
  );
}

DatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  labelCol: PropTypes.number,
  format: PropTypes.string,
  popupClass: PropTypes.string,
};

DatePicker.defaultProps = {
  label: '',
  name: '',
  labelCol: 5,
  format: 'dd.MM.yyyy',
  popupClass: 'date-picker-popup',
};
