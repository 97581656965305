import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { FormikContextType, useFormikContext } from 'formik';

export interface TextFieldProps {
  type?: string;
  label: string;
  name: string;
  placeholder: string;
  [key: string]: unknown;
}

export default function TextField(props: TextFieldProps): JSX.Element {
  const {
    values,
    handleChange,
    touched,
    errors,
    handleBlur,
  }: FormikContextType<any> = useFormikContext();
  const { type = 'text' } = props;

  return (
    <Form.Group as={Row}>
      <Col sm={12}>
        <Form.Label>{props.label}</Form.Label>
      </Col>
      <Col sm={12}>
        <Form.Control
          type={type}
          placeholder={props.placeholder}
          value={values[props.name]}
          name={props.name}
          onChange={handleChange}
          isInvalid={touched[props.name] && !!errors[props.name]}
          onBlur={handleBlur}
          {...props}
        />
        <Form.Control.Feedback type="invalid">
          {errors[props.name]}
        </Form.Control.Feedback>
      </Col>
    </Form.Group>
  );
}
