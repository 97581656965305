import ContainerOrders from "./ContainerOrders";
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import containerOrderActions from "../../redux/modules/containerOrder/containerOrderActions";
import { createLoadingSelector } from '../../redux/modules/loading/loadingSelectors';
import containerOrderConstants from '../../redux/modules/containerOrder/containerOrderConstants';

const isContainerOrdersLoading = createLoadingSelector(containerOrderConstants.GET_CONTAINERORDER);

function mapStateToProps(state) {
  return {
    isLoading: isContainerOrdersLoading(state),
    containerOrders: state.containerOrder.containerOrders,
    selectedOrderId: state.containerOrder.selectedOrderId,
    dateFilterValue: state.containerOrder.dateFilterValue,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    containerOrderActions: bindActionCreators(containerOrderActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContainerOrders);
